<template>
  <div class="cell-wrapper">
    <img :src="img" class="image" />
    <div class="right">
      <div class="title h2 single-line">{{ info.expertName }}</div>
      <div class="content">{{ info.experience }}</div>
      <div class="function-footer">
        <div class="button" @click="handleClick">查看详情</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data() {
    return {}
  },
  computed: {
    img() {
      if (this.info.itemPicUrl) {
        return this.info.itemPicUrl
      }
      if (this.info.photoUrl) {
        return this.info.photoUrl
      }
      if (this.info.pictureUrl) {
        return this.info.pictureUrl
      }
      return ''
    },
  },
  created() { },
  mounted() {

  },
  methods: {
    handleClick() {
      this.$emit('handleClick', this.info)
    },
  }
}
</script>

<style scoped lang="less">
.cell-wrapper {
  height: 120px;
  display: flex;
  align-items: stretch;
  padding: 30px;
  border-bottom: #dfdfdf 1px solid;

  .image {
    width: 200px;
    height: 100%;
    margin-right: 20px;
    object-fit: cover;
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .title {
      height: 30px;
    }

    .content {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .function-footer {
      display: flex;
      justify-content: right;

      .button {
        height: 40px;
        width: 150px;
        border: solid 1px #dfdfdf;
        line-height: 40px;
        cursor: pointer;
        border-radius: 15px;
        text-align: center;
      }
    }
  }
}

.cell-wrapper:hover {
  color: #27a5f9;
  background: #eef8ff;

  // transform: scaleX(1.045);
  .button {
    background-color: #0046c0;
    color: #fff;
  }
}
</style>
